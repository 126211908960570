import React from "react";

import Button from "components/Button";
import DynamicSubComponent from "components/dynamicSubComponent";


function DualTextWithCta({ blok }) {
  const titleLineOne = blok.title_line_one;
  const titleLineTwo = blok.title_line_two;

  const ctaTitle = blok.cta_title;
  const ctaLink = blok.cta_link;

  const isLastBlock = (index) => {
    return index == blok.blocks.length - 1;
    };


  const wideDisplay = blok.wide_display;

  return (
      <section className={`section-container two-columns ${wideDisplay ? `wide-display` : ``}`}>
        <div>
          {titleLineOne != null && titleLineOne != "" && (
            <h2 className="font-helvetica fs-24px font-medium">{titleLineOne}</h2>
          )}
          {titleLineTwo != null && titleLineTwo != "" && (
            <p className="font-helvetica fs-24px text-gray-1 font-medium">{titleLineTwo}</p>
          )}
        </div>
        <div>
          {blok.blocks.map((blok, index) => (
            <DynamicSubComponent blok={blok} key={blok._uid} isLast={isLastBlock(index)} />
          ))}
          {ctaTitle != null && ctaTitle != "" && (
            <div className="pt-[63px] lg:pt-[45px]">
            <Button label={ctaTitle} link={ctaLink} />
            </div>
          )}
        </div>
      </section>
  );
}

export default DualTextWithCta;
