import * as React from "react"

function Logo(props) {
  return (
    <svg 
      id="Layer_1" 
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 1765.81 143.5"  
      {...props}
      className='logo'
    >
    <defs>
      <style>{".cls-1{fill:currentColor}"}</style>
    </defs>
    <g>
      <polygon className="cls-1" points="239.15 0 239.15 22.15 297.59 22.15 297.59 143.49 322.2 143.49 322.2 22.15 380.64 22.15 380.64 0 239.15 0"/>
      <polygon className="cls-1" points="196.56 0 164.06 131.76 131.55 0 113.23 0 107.44 0 89.12 0 56.62 131.76 24.1 0 0 0 35.4 143.49 53.72 143.49 59.5 143.49 77.83 143.49 110.33 11.74 142.83 143.49 161.16 143.49 166.95 143.49 185.28 143.49 220.68 0 196.56 0"/>
    </g>
    <g>
      <polygon className="cls-1" points="925.47 131.75 892.97 0 874.64 0 868.85 0 850.54 0 818.03 131.75 785.52 0 761.41 0 796.81 143.49 815.13 143.49 820.92 143.49 839.24 143.49 871.75 11.74 904.25 143.49 922.57 143.49 928.37 143.49 946.69 143.49 982.09 0 957.97 0 925.47 131.75"/>
      <polygon className="cls-1" points="1452.23 22.15 1510.67 22.15 1510.67 143.49 1535.27 143.49 1535.27 22.15 1593.72 22.15 1593.72 0 1452.23 0 1452.23 22.15"/>
      <polygon className="cls-1" points="1368.72 0 1344.11 0 1344.11 143.49 1462.02 143.49 1462.02 121.34 1368.72 121.34 1368.72 0"/>
      <polygon className="cls-1" points="1741.2 0 1741.2 60.22 1653.23 60.58 1653.23 0 1628.63 0 1628.63 143.49 1653.23 143.49 1653.23 82.73 1741.2 82.37 1741.2 143.49 1765.81 143.49 1765.81 0 1741.2 0"/>
      <path className="cls-1" d="M1241.57,0h-27.23l-57.18,143.49h27.23l12.1-30.36h81.27l12.09,30.36h27.23L1259.88,0h-18.31Zm-36.02,90.37l31.56-79.21,31.56,79.21h-63.13Z"/>
      <polygon className="cls-1" points="1016.34 0 1016.34 22.15 1016.34 60.67 1016.34 82.82 1016.34 143.5 1134.24 143.5 1134.24 121.35 1040.94 121.35 1040.94 82.71 1134.24 82.32 1134.24 60.17 1040.94 60.57 1040.94 22.15 1134.24 22.15 1134.24 0 1016.34 0 1016.34 0"/>
    </g>
    </svg>
  )
}

export default Logo
