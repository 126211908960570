import React, {useRef} from "react";
import Footer from "sections/Footer";
import Navbar from "sections/Navbar";
import Styled from "styled-components";

const LayoutWrapper = Styled.div`


`;


function Layout(props) {
  const containerRef = useRef(null);

  return (
    

    <LayoutWrapper data-scroll-container ref={containerRef} {...props}>
      <main className="min-h-[100vh]">
        <Navbar/>
        {props.children}
        <Footer/>

      </main>
    </LayoutWrapper>

  );
}

export default Layout;
